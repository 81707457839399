import React from "react";
import Margin from "../../../../hoc/Margin/Margin";
import classes from "./AppUpdateInfo.module.css";
import appImage from "../../../../assets/images/blokaspaai-app-purchase.jpg";

const AppUpdateInfo = props => (
   <React.Fragment>
      <Margin bgColor="#395A6C">
         <article className={classes.AppUpdateInfo}>
            <div className={classes.Content}>
               <h2>In die nuutste weergawe</h2>
               <h4>Blokaspaai 1.2.18</h4>
               <p>
                  Blokaspaai is nou gratis geskikbaar in beide die Apple winkel
                  sowel as GooglePlay. Die basiese funksie van die applikasie
                  bly dieselfde.
               </p>
               <p>
                  Ons het 'n puntestelsel ingebou om so bietjie teen jouself te
                  kompeteer. Wanneer jy Blokaspaai aflaai, kry jy 50 gratis
                  'blokkies'. Blokkies kan gebruik word om na antwoorde te
                  'loer'. Aan die einde van elke raaisel ken Blokaspaai aan jou
                  bonus blokkies toe (gebruik die 'Sien alles na' funksie) vir
                  regte letters. Hierdie 'blokkies' word by jou balans gevoeg.
                  Indien jou balans nul blokkies bereik, word jy die geleentheid
                  te bied om jou 'blokkies' aan te vul deur addisionele
                  'blokkies' te koop.
               </p>
               <p>
                  Daar is ook nou ‘n funksie ingebou wat jou vordering volg
                  sodat jy presies weet waar jy staan met al jou invulwerk
                  wanneer jy 'n boek oopmaak.
               </p>
               <h3>Kennisgewing</h3>
               <p>
                  Tydens opgradering na die nuwe weergawe op Apple, word jou
                  lokale databasis uitgevee. Dus sal jy die geleentheid hê om al
                  jou raaisels van nuut af in te vul en gebruik te maak van al
                  die bonus blokkies! ’n Lekker uitdaging.
               </p>
               <h3>Vrywaring</h3>
               <p>
                  Omdat dit vir ons belangrik is dat Blokaspaai sonder enige
                  internetkonneksie kan werk, word al die data op jou
                  slimfoon/tablet gestoor nadat dit afgelaai is. LET WEL dat
                  latere herinstallasie van die applikasie moontlik kan
                  veroorsaak dat jy alle ‘blokkies’ (gratis en gekoop) verbeur.
               </p>
            </div>
            <div className={classes.Image}>
               <img src={appImage} alt="Blokaspaai Winkel" style={{width: "100%"}} />
            </div>
         </article>
         <div className={classes.Space} />
      </Margin>
   </React.Fragment>
);

export default AppUpdateInfo;
