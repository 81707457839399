import React from "react";
import classes from "./Feature.module.css";
import Margin from "../../../hoc/Margin/Margin";
import Card from "./Card/Card";

import imgKeyboard from "../../../assets/images/keyboard.jpg";
import imgBlocks from "../../../assets/images/blocks.jpg";
import imgBooks from "../../../assets/images/books.jpg";

const Feature = props => (
   <React.Fragment>
      <Margin bgColor="#395A6C">
         <div className={classes.Features}>
            <Card
               bgImage={imgKeyboard}
               text="Maklik om in te vul met leidrade bo die sleutelbord"
            />
            <Card
               bgImage={imgBlocks}
               text="Ruil blokkies om na antwoorde te ‘loer’"
            />
            <Card
               bgImage={imgBooks}
               text="Groot verskeidenheid Afrikaans en Engelse boeke is beskikbaar"
            />
         </div>
      </Margin>
   </React.Fragment>
);

export default Feature;
