import React from "react";
import classes from "./Logo.module.css";
import siteLogoColor from "../../../assets/images/blokaspaai.svg";
import siteLogoWhite from "../../../assets/images/blokaspaai-white.svg";

const Logo = props => {
   let siteLogo = siteLogoColor;
   if (props.isWhite === true) siteLogo = siteLogoWhite;

   return (
      <div className={classes.Logo} style={{ height: props.height }}>
         <img src={siteLogo} alt="Blokaspaai" />
      </div>
   );
};

export default Logo;
