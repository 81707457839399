import React, {  useContext, useState } from "react";

import classes from "./Layout.module.css";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
import Footer from "../../components/UI/Footer/Footer";
import FirebaseContextProvider from "../../components/Firebase/FirebaseContext";

const Layout = props => {
  const firebase = useContext(FirebaseContextProvider);

  console.log('firebase', firebase)

  const [showSideDrawer, setShowSideDrawer] = useState(false);

  const sideDrawerClosedHandler = () => {};

  const sideDrawerToggleHandler = () => {
    setShowSideDrawer(!showSideDrawer);
  };

  //const user = firebase.auth().currentUser;
  //const isAuthenticated = user != null;

  return (
    <React.Fragment>
      <div>
        <Toolbar
          drawerToggleClicked={sideDrawerToggleHandler}
          //isAuthenticated={isAuthenticated}
        />
        <SideDrawer open={showSideDrawer} closed={sideDrawerClosedHandler} />
      </div>
      <main className={classes.Content}>{props.children}</main>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
