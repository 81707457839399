import React from "react";
import "./Landing.css";
import classes from "./Landing.module.css";
import phoneImg from "../../assets/images/mobile.png";
import appStoreBadge from "../../assets/images/app-store-badge.svg";
import googlePlayBadge from "../../assets/images/google-play-badge.svg";
import NavigationItem from "../Navigation/NavigationItems/NavigationItem/NavigationItem";
import Feature from "../UI/Feature/Feature";
import Margin from "../../hoc/Margin/Margin";
import AppUpdateInfo from "../UI/Feature/AppUpdateInfo/AppUpdateInfo";

const Landing = props => (
   <React.Fragment>
      <Margin>
         <section className={classes.MainArea}>
            <div className={classes.MainImage}>
               <img
                  src={phoneImg}
                  alt="Blokaspaai op 'n iPhone"
                  width="374px"
               />
            </div>
            <div className={classes.MainContent}>
               <h2>Blokraaisels in jou taal</h2>
               <h1>Maksimaal digitaal</h1>
               <p className="intro">
                  Blokaspaai bied 'n nuwe dimensie aan 'n ou
                  gunstelingtydverdryf. Spesiaal ontwerp om maklik in te vul
                  sonder onnodige tierlantyntjies en fieterjasies.
               </p>
               <p>
                  Van Suid-Afrika se bekendste opstellers publiseer gereeld
                  hulle eie boeke teen sakpaspryse. Jy sal vir seker nooit
                  sonder 'n kopkrap-lekkerte sit nie.
               </p>
               <div className={classes.Anchor}>
                  <div className={classes.Separator} />
               </div>
               <ul>
                  <NavigationItem
                     link="https://apps.apple.com/ag/app/blokaspaai/id721184219"
                     target="_blank"
                     noFormat
                     type="external"
                  >
                     <img src={appStoreBadge} alt="Kry dit op die App Store" />
                  </NavigationItem>
                  <NavigationItem
                     link="https://play.google.com/store/apps/details?id=za.co.shine.blokaspaai.gps"
                     target="_blank"
                     noFormat
                     type="external"
                  >
                     <img
                        src={googlePlayBadge}
                        alt="Kry dit op Google Play Store"
                     />
                  </NavigationItem>
               </ul>
            </div>
         </section>
      </Margin>
      <Feature />
      <AppUpdateInfo />
   </React.Fragment>
);

export default Landing;
