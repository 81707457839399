import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./app/util/ScrollToTop";

import { ToastContainer } from "react-toastr";
import ModalContextProvider from "./components/Modal/ModalContext";
import FirebaseContextProvider from "./components/Firebase/FirebaseContext";
// import AuthContextProvider from "./components/auth/AuthContext";

// const store = configureStore();

// const rrfConfig = {
//   userProfile: "users",
//   attachAuthIsReady: true,
//   useFirestoreForProfile: true,
//   updateProfileOnLogin: false
// };

// const rrfProps = {
//   firebase,
//   config: rrfConfig,
//   dispatch: store.dispatch,
//   createFirestoreInstance
// };

// function AuthIsLoaded({ children }) {
//   const auth = useSelector(state => state.firebase.auth);
//   if (!isLoaded(auth)) return <LoadingComponent />;
//   return children;
// }

const app = (
  <BrowserRouter>
    <FirebaseContextProvider>
      <ModalContextProvider>
        <ScrollToTop />
        <ToastContainer
          position='bottom-right'
          
        />
        <App />
      </ModalContextProvider>
    </FirebaseContextProvider>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
