import React from "react";
import facebook from "../../../assets/images/facebook-square-white.svg";
import twitter from "../../../assets/images/twitter-square-white.svg";
import NavigationItem from "../../Navigation/NavigationItems/NavigationItem/NavigationItem";
import classes from "./Socialmedia.module.css";

const Socialmedia = props => {
   let facebookLink = null;
   let twitterLink = null;

   if (!props.hideFacebook) {
      facebookLink = (
         <NavigationItem
            link="https://www.facebook.com/Blokaspaai"
            type="external"
            target="_blank"
         >
            <img src={facebook} alt="Facebook" style={{ height: "32px" }} />
         </NavigationItem>
      );

      if (props.facebook) {
         facebookLink = (
            <NavigationItem
               link={props.facebook}
               type="external"
               target="_blank"
            >
               <img src={facebook} alt="Facebook" style={{ height: "32px" }} />
            </NavigationItem>
         );
      }
   }
   if (!props.hideTwitter) {
      twitterLink = (
         <NavigationItem
            link="https://twitter.com/blokaspaai"
            type="external"
            target="_blank"
         >
            <img src={twitter} alt="Twitter" style={{ height: "32px" }} />
         </NavigationItem>
      );

      if (props.twitter) {
         twitterLink = (
            <NavigationItem
               link={props.twitter}
               type="external"
               target="_blank"
            >
               <img src={twitter} alt="Twitter" style={{ height: "32px" }} />
            </NavigationItem>
         );
      }
   }

   return (
      <React.Fragment>
         <ul className={classes.Socialmedia}>
            {facebookLink}
            {twitterLink}
         </ul>
      </React.Fragment>
   );
};

export default Socialmedia;
