import React, { Component } from "react";
import Layout from "./hoc/Layout/Layout";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import asyncComponent from "./hoc/asyncComponent";
import Landing from "./components/Landing/Landing";

//lazy loading

const asyncAboutUs = asyncComponent(() => {
  return import("./components/About/About");
});

const asyncProducts = asyncComponent(() => {
  return import("./components/Products/Products");
});

const asyncContact = asyncComponent(() => {
  return import("./components/ContactUs/ContactUs");
});

const asyncPrivacy = asyncComponent(() => {
  return import("./components/Privacy/Privacy");
});

const asyncBookstore = asyncComponent(() => {
  return import("./components/Bookstore/Bookstore");
});

const asyncBook = asyncComponent(() => {
  return import("./components/Bookstore/Book/Book");
});

const asyncBookEdit = asyncComponent(() => {
  return import("./components/Bookstore/Book/BookForm");
});

const asyncPuzzleEdit = asyncComponent(() => {
  return import("./components/Bookstore/Book/Puzzle/PuzzleForm");
});

class App extends Component {

  
  render() {

    let routes = (
      <Switch>
        <Route path='/tuis' component={Landing} />
        <Route path='/meer' component={asyncAboutUs} />
        <Route path='/produkte' component={asyncProducts} />
        <Route path='/kontak' component={asyncContact} />
        <Route path={["/privaatheid", "/privacy"]} component={asyncPrivacy} />

        {/* TODO: Add auth routes */}
        <Route path='/bookstore' component={asyncBookstore} />
        <Route path={['/book/edit/:bookId/:puzzleId']} exact component={asyncPuzzleEdit} />
        <Route path={['/book/edit/:id', '/book/new']} exact component={asyncBookEdit} />
        <Route path='/book/:id' component={asyncBook} />
        <Route path='/' component={Landing} />
        <Redirect to='/' />
      </Switch>
    );

    return (
      <div>
        <Layout>{routes}</Layout>
      </div>
    );
  }
}

export default withRouter(App);
