import React, { useContext } from "react";
import { Modal, Button, Divider } from "semantic-ui-react";

import { withRouter } from "react-router";
import { ModalContext } from "./ModalContext";

const UnauthModal = () => {
  const modal = useContext(ModalContext);

  const handleCloseModal = () => {
    if (!this.props.stayOnPage) this.props.history.goBack();
    modal.close();
  };

  return (
    <Modal size='mini' open={true} onClose={handleCloseModal}>
      <Modal.Header>You need to be signed in to do that!</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>Please either login or register to see this page</p>
          <Button.Group widths={4}>
            <Button fluid color='teal' onClick={() => modal.open("LoginModal")}>
              Login
            </Button>
            <Button.Or />
            <Button fluid positive onClick={() => modal.open("RegisterModal")}>
              Register
            </Button>
          </Button.Group>
          <Divider />
          <div style={{ textAlign: "center" }}>
            <p>Or click cancel to continue as a guest</p>
            <Button onClick={this.handleCloseModal}>Cancel</Button>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(UnauthModal);
