import React, { createContext, useState } from "react";

import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import UnauthModal from "./UnauthModal";
import PuzzleModal from "./PuzzleModal";
import PuzzleEditModal from "./PuzzleEditModal";

export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";

export const ModalContext = createContext();

const ModalContextProvider = props => {
  const [modal, setModal] = useState({
    type: "",
    props: ""
  });

  const modalLookup = {
    LoginModal,
    RegisterModal,
    UnauthModal,
    PuzzleModal,
    PuzzleEditModal
  };

  const open = (modalType, modalProps) => {
    setModal({
      type: modalType,
      props: modalProps
    });
  };

  const close = () => {
    setModal({
      type: "",
      props: ""
    });
  };
  
  let modalData="";

  if (modal.type) {
    const ModalComponent = modalLookup[modal.type];
    modalData = (
      <span>
        <ModalComponent {...modal.props} />;
      </span>
    );
  }

  return (
    <ModalContext.Provider
      value={{ type: modal.type, props: modal.props, open, close }}
    >
      {modalData}
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
