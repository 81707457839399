import React, { useContext } from "react";
import { Modal } from "semantic-ui-react";
import RegisterForm from "../auth/register/RegisterForm";
import { withRouter } from "react-router";
import { ModalContext } from "./ModalContext";

const RegisterModal = () => {
  const modal = useContext(ModalContext);

  const handleCloseModal = () => {
    this.props.history.goBack();
    modal.close();
  };

  return (
    <Modal size='mini' open={true} onClose={handleCloseModal}>
      <Modal.Header>Blokaspaai</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <RegisterForm />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(RegisterModal);
