import React from "react";

import classes from "./Margin.module.css";

const Margin = props => {
   const bgColor = props.bgColor ? props.bgColor : "none";

   return (
      <React.Fragment>
         <div style={{ backgroundColor: bgColor }}>
            <section className={classes.Content}>{props.children}</section>
         </div>
      </React.Fragment>
   );
};

export default Margin;
