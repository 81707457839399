import React from "react";
import classes from "./Footer.module.css";
import Logo from "../Logo/Logo";
import Socialmedia from "../Socialmedia/Socialmedia";
import NavigationItem from "../../Navigation/NavigationItems/NavigationItem/NavigationItem";

const Footer = props => (
   <footer className={classes.Footer}>
      <div className={classes.Content}>
         <div className={classes.Logo} >
            <Logo isWhite={true} height="60px" />
            <NavigationItem
                  link="https://shine.co.za"
                  type="external"
                  target="_blank"
                  className={classes.NavigationItem}
               >
                  ©2020 Shine Interactive
               </NavigationItem>
         </div>
         
         <div className={classes.MenuGroup}>
            <h4>Volg ons</h4>
            <Socialmedia />
         </div>
         <div className={classes.MenuGroup} style={{width: "200px"}}>
            <h4>Laai die toep af</h4>
            <ul>
               <NavigationItem
                  link="https://apps.apple.com/ag/app/blokaspaai/id721184219"
                  type="external"
                  target="_blank"
                  className={classes.NavigationItem}
               >
                  App Store
               </NavigationItem>
               <NavigationItem
                  link="https://play.google.com/store/apps/details?id=za.co.shine.blokaspaai.gps"
                  type="external"
                  target="_blank"
                  className={classes.NavigationItem}
               >
                  Google Play Store
               </NavigationItem>
            </ul>
         </div>
         <div className={classes.MenuGroup} style={{width: "200px"}}>
            <h4>Skakels</h4>
            <ul>
               <NavigationItem
                  link="/kontak"
                  className={classes.NavigationItem}
               >
                  Kontak ons
               </NavigationItem>
               <NavigationItem
                  link="/privaatheid"
                  className={classes.NavigationItem}
               >
                  Privaatheidsbeleid
               </NavigationItem>
            </ul>
         </div>
      </div>
   </footer>
);

export default Footer;
