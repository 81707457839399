import React, { useContext } from "react";
import { Modal } from "semantic-ui-react";
import { ModalContext } from "./ModalContext";
import PuzzleForm from "../Bookstore/Book/Puzzle/PuzzleForm";
import { withRouter } from "react-router";

const PuzzleEditModal = ({puzzle, book}) => {
  const modal = useContext(ModalContext);

  const handleCloseModal = () => {
    modal.close();
  };

  return (
    <Modal size='small' open={true} onClose={handleCloseModal}>
      <Modal.Header>Blokaspaai</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <PuzzleForm close={handleCloseModal} book={book} puzzle={puzzle} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(PuzzleEditModal);
