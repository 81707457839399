import React, { useContext, useState } from "react";
import { Form, Segment, Button, Label } from "semantic-ui-react";
// import SocialLogin from "../socialLogin/SocialLogin";

import { useForm } from "react-hook-form";
import { ModalContext } from "../../Modal/ModalContext";
import { useEffect } from "react";
import { FirebaseContext } from "../../Firebase/FirebaseContext";

// const EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

// const actions = {
//   login,
//   socialLogin
// };

const LoginForm = () => {
  const { register, handleSubmit } = useForm();
  const firebase = useContext(FirebaseContext);
  const { close } = useContext(ModalContext);
  const [error, setError] = useState(null)

  const onSubmit = async data => {
    console.log("Submit Start");
    try {
      await firebase
        .auth
        .signInWithEmailAndPassword(data.email, data.password);
      console.log("Submit End");
      close();
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (firebase.result) close();
  }, [firebase, close]);

  //console.log('auth result', auth.result.profile

  return (
    <Form size='large' onSubmit={handleSubmit(onSubmit)} autoComplete='false'>
      <Segment>
        <Form.Field>
          <input
            name='email'
            ref={register({ required: true })}
            placeholder='Email Address'
          />
        </Form.Field>
        <Form.Field>
          <input
            name='password'
            type='password'
            placeholder='password'
            ref={register({ required: true })}
          />
        </Form.Field>

        {error && (
          <Label basic color='red' pointing>
            {error.message}
          </Label>
        )}
        <Button /*loading={submitting}*/ fluid size='large' color='teal'>
          Login
        </Button>
        {/* <Divider horizontal>or</Divider> */}
        {/* <SocialLogin socialLogin={socialLogin} /> */}
      </Segment>
    </Form>
  );
};

export default LoginForm;
