import React, { useContext } from "react";
import {
  Form as SForm,
  Segment,
  Button,
  Grid,
  Header,
} from "semantic-ui-react";
import { FirebaseContext } from "../../../Firebase/FirebaseContext";
import { Form, Field } from "react-final-form";
import { TextInput } from "../../../../app/common/form/TextInput";
import TextArea from "../../../../app/common/form/TextArea";
import { format } from "date-fns";

const PuzzleForm = ({ close, book, puzzle }) => {
  const puzzleId = puzzle.id;
  const firebase = useContext(FirebaseContext);

  const onSubmit = async (data) => {
    console.log("data", data);
    try {
      let eventDocref = firebase.db
        .collection("books")
        .doc(book.id)
        .collection("puzzles")
        .doc(puzzleId);

      await eventDocref.update(data);

      close();
      // toastr.success("Success!", "Event has been updated");
    } catch (error) {
      console.log(error);
      // toastr.error("Oops!", "Something went wrong");
    }
  };

  const today = new Date();

  const closeForm = (e) => {
    e.preventDefault();
    close();
  };

  return (
    <Segment>
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          if (!values.title) {
            errors.title = "Title can't be empty";
          }

          return errors;
        }}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <SForm onSubmit={handleSubmit}>
              <Field
                // label='Title'
                name='title'
                component={TextInput}
                initialValue={puzzle.title}
                placeholder='Puzzle title'
              />

              <Field
                // label='Original ID'
                name='originalId'
                component={TextInput}
                initialValue={puzzle.originalId}
                placeholder='original ID (blank for none)'
              />

              <Field
                // label='Creator'
                name='creator'
                component={TextInput}
                initialValue={puzzle.creator}
                placeholder='Puzzle creator / editor'
              />

              <Field
                // label='Description'
                name='description'
                component={TextArea}
                rows='2'
                initialValue={puzzle.description}
                placeholder='Puzzle description / theme'
              />

              <Field
                // label='Copyright'
                name='copyright'
                component={TextInput}
                initialValue={puzzle.copyright}
                placeholder='Copyright Holder'
              />
              {book.type === "challenge" && (
                <Segment>
                  <Header as='h3'>Challenge</Header>
                  <Grid divided='vertically'>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Field
                          label='Year'
                          name='challengeYear'
                          component={TextInput}
                          initialValue={puzzle.challengeYear ?? today.getFullYear()}
                          placeholder='Challenge Year'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          label='Week Number'
                          name='challengeWeek'
                          component={TextInput}
                          initialValue={puzzle.challengeWeek ?? format(today, 'w')}
                          placeholder='Challenge Week Number'
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              )}

              <Button
                type='submit'
                loading={submitting}
                disabled={submitting || pristine}
                size='large'
                color='teal'
              >
                Save
              </Button>
              <Button
                loading={submitting}
                disabled={submitting}
                size='large'
                onClick={closeForm}
              >
                Cancel
              </Button>
            </SForm>
          );
        }}
      />
    </Segment>
  );
};

export default PuzzleForm;
