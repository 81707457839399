import React, { useContext, Fragment } from "react";
import classes from "./NavigationItems.module.css";
import NavigationItem from "./NavigationItem/NavigationItem";
import facebook from "../../../assets/images/facebook-square.svg";
import twitter from "../../../assets/images/twitter-square.svg";
import { withRouter } from "react-router";
import { FirebaseContext } from "../../Firebase/FirebaseContext";

const NavigationItems = ({ history }) => {
  const firebase = useContext(FirebaseContext);

  const handleSignOut = (e) => {
    e.preventDefault();
    firebase.auth.signOut();
    history.push("/");
  };

  const authenticated = firebase.auth.currentUser != null;

  return (
    <ul className={classes.NavigationItems}>
      <NavigationItem link='/meer' exact>
        meer oor ons
      </NavigationItem>
      <NavigationItem link='/produkte' exact>
        produkte
      </NavigationItem>
      <NavigationItem link='/kontak' exact>
        kontak ons
      </NavigationItem>
      {authenticated && (
        <Fragment>
          <NavigationItem link='/bookstore'>boekrak</NavigationItem>
          <NavigationItem link={handleSignOut} type='action'>
            afmeld
          </NavigationItem>
        </Fragment>
      )}

      <span className={classes.NavigationItemsExtended}>
        <NavigationItem
          link='https://www.facebook.com/Blokaspaai'
          type='external'
          target='_blank'
        >
          <img src={facebook} alt='Facebook' style={{ height: "100%" }} />
        </NavigationItem>
        <NavigationItem
          link='https://twitter.com/blokaspaai'
          type='external'
          target='_blank'
        >
          <img src={twitter} alt='Twitter' style={{ height: "100%" }} />
        </NavigationItem>
      </span>

      {/* {options} */}
    </ul>
  );
};

export default withRouter(NavigationItems);
