import React from "react";
import { Form, Label } from "semantic-ui-react";

export const TextInput = ({
  input,
  type,
  label,
  placeholder,
  meta,
  meta: { touched, error }
}) => {
  return (
    <Form.Field error={touched && !!error}>
      {label && <label>{label}</label>}
      <input {...input} placeholder={placeholder} type={type} />
      {touched && error && (
        <Label basic color='red'>
          {error}
        </Label>
      )}
    </Form.Field>
  );
};
