import React, { useContext } from "react";
import { Modal } from "semantic-ui-react";
import LoginForm from "../auth/login/LoginForm";
import { withRouter } from "react-router";
import { ModalContext } from "./ModalContext";

const RegisterModal = () => {
  const modal = useContext(ModalContext);

  const handleCloseModal = () => {
    if (!modal.props.stayOnPage) this.props.history.goBack();
    modal.close();
  };

  console.log(modal)

  return (
    <Modal size='mini' open={true} onClose={handleCloseModal}>
      <Modal.Header>Blokaspaai</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <LoginForm />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(RegisterModal);
