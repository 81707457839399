import React from 'react';
import { Form, Label } from 'semantic-ui-react';

const TextArea = ({
	input,
	width,
	type,
	rows,
	placeholder,
	label,
	disabled,
	meta: { touched, error }
}) => {
	return (
		<Form.Field error={touched && !!error} width={width}>
			{label && <label>{label}</label>}
			<textarea
				{...input}
				rows= {rows || 3}
				placeholder={placeholder}
				type={type}
				disabled={disabled}
			/>
			{touched && error && (
				<Label basic color="red" pointing>
					{error}
				</Label>
			)}
		</Form.Field>
	);
};

export default TextArea;