import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAKQm2MA5xTWMGLwx6EUG0TzvWuL-ERlJw",
  authDomain: "blokaspaai.firebaseapp.com",
  databaseURL: "https://blokaspaai.firebaseio.com",
  projectId: "blokaspaai",
  storageBucket: "blokaspaai.appspot.com",
  messagingSenderId: "1043388181677",
  appId: "1:1043388181677:web:a733ac2354ecabfb90ea24",
  measurementId: "G-J2SHJLKEM5",
};

const app = firebase.initializeApp(firebaseConfig);

export default app;
