import React, { useContext, useEffect, useState } from "react";
import { Modal, Loader } from "semantic-ui-react";
import { withRouter } from "react-router";
import { ModalContext } from "./ModalContext";
// import { FirebaseContext } from "../Firebase/FirebaseContext";
import classes from "./PuzzleModal.module.css";
import { FirebaseContext } from "../Firebase/FirebaseContext";

const PuzzleModal = ({ bookId, puzzle }) => {
  const modal = useContext(ModalContext);
  const firebase = useContext(FirebaseContext);

  const [puzzleData, setPuzzleData] = useState("");

  useEffect(() => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function (event) {
      var blob = xhr.response;

      var reader = new FileReader();
      reader.onload = (e) => {
        let readXml = e.target.result;

        var parser = new DOMParser();
        var doc = parser.parseFromString(readXml, "application/xml");

        setPuzzleData(doc);
      };
      reader.onerror = (e) => {
        console.log("error", e);
      };
      reader.readAsText(blob);
    };
    xhr.open("GET", puzzle.url);
    xhr.send();

    return () => {};
  }, [puzzle.url]);

  const handleCloseModal = () => {
    modal.close();
  };

  const renderPuzzle = () => {
    const grid = puzzleData.getElementsByTagName("grid")[0].childNodes;
    const width = puzzleData
      .getElementsByTagName("grid")[0]
      .getAttribute("width");
    const height = puzzleData
      .getElementsByTagName("grid")[0]
      .getAttribute("height");

    let output = [];

    for (var i = 0; i < grid.length; i++) {
      const cell = grid[i];
      
      switch (cell.nodeName) {
        case "grid-look":
        case "text":
          break;

        default:
          const x = cell.getAttribute("x");
          const y = cell.getAttribute("y");
          const type = cell.getAttribute("type");
          switch (type) {
            case "clue":
              const clues = cell.childNodes;
              let clueOutput = [];

              for (var j = 0; j < clues.length; j++) {
                let clue = clues[j].textContent;
                let parts = clue.split("\\n");
                clue = [];
                for (let k = 0; k < parts.length; k++) {
                  const element = parts[k];
                  clue.push(<div key={k}>{element}</div>);
                }

                const word = clues[j].getAttribute("word");

                clueOutput.push(
                  <div key={j} word={word} className={classes.clueCell}>
                    {clue}
                  </div>
                );
              }

              output.push(
                <div key={`${x}-${y}`} className={classes.clue}>
                  {clueOutput}
                </div>
              );
              break;

            default:
              const solution = cell.getAttribute("solution");
              output.push(
                <div key={`${x}-${y}`} className={classes.cell}>
                  {firebase.isAdmin && solution}
                </div>
              );
          }
      }
    }

    return (
      <div
        className={classes.grid}
        style={{
          gridTemplateColumns: `repeat(${width}, 1fr)`,
          gridTemplateRows: `repeat(${height}, 1fr)`,
        }}
      >
        {output}
      </div>
    );

    // return puzzleData.getElementsByTagName("grid")[0].map((node) => {
    //   return <p>Hello</p>;
    // });
  };

  return (
    <Modal size="large" open={true} onClose={handleCloseModal}>
      <Modal.Header>{puzzle.title}</Modal.Header>
      <Modal.Content>
        {!puzzleData && <Loader className='workaround'>Loading</Loader>}
        {puzzleData && renderPuzzle()}
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(PuzzleModal);
