import React, { createContext, useEffect } from "react";
import app from "./FirebaseApp";
import { useState } from "react";

export const FirebaseContext = createContext();

const FirebaseContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAuthState = () => {
      if (app.auth().currentUser !== null)
        app
          .auth()
          .currentUser.getIdTokenResult(true)
          .then((idTokenResult) => {
            // console.log("idTokenResult", idTokenResult.claims.admin);
            setIsAdmin(idTokenResult.claims.admin);
          })
          .catch((error) => {
            console.log(error);
          });
      else setIsAdmin(false);
    };

    app.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setIsLoading(false);
      checkAuthState();
    });
  }, [currentUser]);

  return (
    <FirebaseContext.Provider
      value={{
        currentUser,
        isLoading,
        auth: app.auth(),
        app,
        db: app.firestore(),
        storage: app.storage(),
        isAdmin: isAdmin,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContextProvider;
