import React from "react";
import classes from "./Card.module.css";

const Card = props => (
   <div className={classes.Card}>
      <div className={classes.Content}>
         <p>{props.text}</p>
      </div>
      <div
         style={{ backgroundImage: "url(" + props.bgImage + ")" }}
         className={classes.Image}
      />
      
     
   </div>
);

export default Card;
