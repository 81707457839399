import React from "react";
import classes from "./DrawerToggle.module.css";
import pancake from '../../../../assets/images/menu.svg'

const DrawerToggle = props => (
   <div className={classes.DrawerToggle} onClick={props.clicked}>
       <img src={pancake} alt="Menu"/>
   </div>
);

export default DrawerToggle;
