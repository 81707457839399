import React from "react";
import classes from "./Toolbar.module.css";
import Logo from "../../UI/Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";
import NavigationItem from "../NavigationItems/NavigationItem/NavigationItem";

const toolbar = props => (
  <header className={classes.Toolbar}>
    <div className={classes.Logo}>
      <NavigationItem noFormat link='/'>
        <Logo />
      </NavigationItem>
    </div>
    <nav className={classes.DesktopOnly}>
      <NavigationItems />
    </nav>
    <DrawerToggle clicked={props.drawerToggleClicked} />
  </header>
);

export default toolbar;
